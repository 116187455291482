import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import { parseNumberFormat } from '../Utils/Format';
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
const isDesktop = window.screen.width > 940;

function dayInMonthComparator(v1, v2) {
  v1 = `${v1.split('/')[2]}-${v1.split('/')[1]}-${v1.split('/')[0]}`;
  v2 = `${v2.split('/')[2]}-${v2.split('/')[1]}-${v2.split('/')[0]}`;
  return new Date(v1) - new Date(v2);
}

const useStyles = makeStyles({
  alerts: {
    zIndex: 99,
    position: 'fixed',
    bottom: '20px',
    right: '2%',
    left: '2%',
    margin: '0 auto',
    width: '96%',
    maxWidth: '600px',
    '& > * + *': {
      marginTop: '2px'
    }
  }
})

const columns = [
  { field: 'codCli', headerName: 'Cliente', width: 70 },
  {
    field: 'datEmi',
    headerName: 'Data',
    width: 130,
    type: 'date',
    valueGetter: (params) => params.row.datEmi,
    sortComparator: dayInMonthComparator,
  },
  {
    field: 'nomCli',
    headerName: 'Nome do Cliente',
    width: 180,
    valueGetter: (params) => params?.row?.nomCli,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <span>{params.value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'numPed',
    headerName: 'Número do Pedido',
    type: 'number',
    width: 120,
    valueGetter: (params) => params?.row?.numPed,
  },

  {
    field: 'qtd',
    headerName: 'Quantidade',
    sortable: true,
    width: 130,
    type: 'number',
    valueGetter: (params) => params?.row?.qtd,
    renderCell: (params) => parseNumberFormat(params.value)
  },
  {
    field: 'vlrLinha',
    headerName: 'Valor',
    sortable: true,
    width: 130,
    description: 'Não é possível ordenar esta coluna.',
    type: 'number',
    renderCell: (params) => (<span>{`R$ ${parseNumberFormat(params.value, 2)}`}</span>)
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    width: 220,
    valueGetter: (params) => params?.row?.status,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <span>{params.value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'obsPed',
    headerName: 'Observação do Pedido',
    description: 'Não é possível ordenar esta coluna.',
    sortable: true,
    width: 300,
    // flex: 1,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <span>{params.value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'nomRep',
    headerName: 'Nome do Representante',
    width: 180,
    valueGetter: (params) => params?.row?.nomRep,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <span>{params.value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'supRep',
    headerName: 'Nome do Supervisor',
    width: 180,
    valueGetter: (params) => params?.row?.supRep,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <span>{params.value}</span>
      </Tooltip>
    ),
  },
];


export default function TabelaReservados(props) {
  const classes = useStyles()
  const [rows, setRows] = useState([]);
  const [showDescription, setShowDescription] = useState(false)
  const [messageDescription, setMessageDescription] = useState('')

  useEffect(() => {
    if (props?.pedidos?.length) {
      const rowsFormatted = props.pedidos.map(
        ({
          codCli,
          datEmi,
          nomCli,
          numPed,
          obsPed,
          qtd,
          status,
          vlrLinha,
          nomRep,
          supRep
        }) => ({
          id: uuid(),
          codCli,
          datEmi,
          nomCli,
          numPed,
          obsPed,
          qtd,
          status,
          vlrLinha,
          nomRep,
          supRep
        })
      );
      setRows(rowsFormatted);
    }
  }, [props]);

  const handleCellClick = (param) => {
    const fieldsAlert = ['obsPed', 'status', 'nomCli']
    if (fieldsAlert.indexOf(param.field) !== -1 && param.value) {
      setMessageDescription(param.value.toString().trim())
      // props.handleSnackbar({ message: param.value.toString().trim(), variant: 'error', duration: 2000 })
    }
  }

  useEffect(() => {
    if (showDescription) {
      setTimeout(() => setShowDescription(false), 10000)
    }
  }, [showDescription])

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        initialState={{
          sorting: { sortModel: [{ field: 'datEmi', sort: 'desc' }] },
        }}
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        onCellClick={(param) => handleCellClick(param)}
        disableSelectionOnClick
        loading={props.loading}
      />
      <div className={classes.alerts}>
        <Alert
          style={{ display: showDescription ? 'flex' : 'none' }}
          onClose={() => setShowDescription(false)}
          severity='info'
        >
          {messageDescription}
        </Alert>
      </div>
    </div>
  );
}
