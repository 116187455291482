import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { parseNumberFormat } from "../Utils/Format";
import axios from 'axios';

const parseNumberMoney = (value) => {
  if (isNaN(parseFloat(value))) {
    return '0,000'
  }

  return parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3
  })
}
const isSmallScreen = window.screen.width < 1000

const columns = [
  {
    field: "id",
    headerName: "#",
    width: 30,
    headerAlign: "center",
    align: "center"
    // headerClassName: "header-fob",
    // cellClassName: "cell-fob",
   
  },
  {
    field: "pedidocompra",
    headerName: "Pedido Compra",
    width: 120,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "statuspcnwl",
    headerName: "Status Pedido",
    width: 120,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "produto",
    headerName: "Produto",
    width: 80,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "descricaoproduto",
    headerName: "Descrição",
    width: 400,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "moeda",
    headerName: "Moeda",
    width: 80,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "fabricante",
    headerName: "Fabricante",
    width: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "qtde",
    headerName: "Quantidade",
    width: 150,
    headerAlign: "center",
    align: "center"
  },
  {
    field: "preco",
    headerName: "Preco",
    width: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return parseNumberMoney(params.value)
    }
  },
  {
    field: "precoajustado",
    headerName: "Preco Ajustado",
    width: 120,
    headerAlign: "center",
    align: "center",
    //editable: true,
    renderCell: (params) => {
      return parseNumberMoney(params.value)
    }
  },
  {
    field: "datacadastronwl",
    headerName: "Data Compra",
    width: 100,
    headerAlign: "left",
    align: "left"
  },
  {
    field: "comprador",
    headerName: "Comprador",
    width: 150,
    headerAlign: "center",
    align: "center",
  }

  //{
  //field: "datacadastronwl",
  //headerName: "Data Cadastro",
  //type: "date",
  //width: 300,
  //...(!isSmallScreen && {
  //  flex: 1,
  //}),
  //headerAlign: "center",
  //align: "center"
  //  renderCell: (params) => {
  //  if ((params?.value?.split('-')?.length || 0) > 2) {
  //  const dateTemp = params.value.split('-')
  //  const dateFormat = `${dateTemp[2]}/${dateTemp[1]}/${dateTemp[0]}`
  //  return dateFormat
  //}

  //  return params.value
  //  },
  //}
];






const getColumns = (permissions) => {
  const enableManufacturer = permissions && permissions.length > 0
    ? permissions.some(permission =>
      permission.displayName === 'Espelho - Diretoria'
      || permission.displayName === 'Espelho - Importação'
    )
    : false

  if (!enableManufacturer) {
    const index = columns.findIndex(column => column.field === 'fornecedor')
    if (index !== -1) {
      columns.splice(index, 1)
    }
  }

  return columns
}

export default function TabelaFob({ setFobs, fobs, loader, permissions }) {
  const [colunms, setColunms] = React.useState([])
  React.useEffect(() => {
    setColunms(getColumns(permissions))
  }, [permissions])

  const endpoint_api = process.env.REACT_APP_API;

  const editCommit = async (params) => {
    const clone = [...fobs]
    const index = clone.findIndex(row => row.id === params.id)

    try {
      // Chama sua API para atualizar o banco de dados
      
      console.log(params, params.value, 'values')
      await axios.put(`${endpoint_api}/preco-ajustado/${params.id}`, { 
        value: params.value
      },
      {headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }}
      );

      clone[index].precoajustado = params.value
      setFobs(clone)

      // Se a atualização foi bem-sucedida, atualize o estado local
      // const updatedRows = rows.map((row) => {
      // if (row.id === newRow.id) {
      // return { ...row, precoAjustado: newRow.precoAjustado };
      // }
      //   return row;
      // });

      //  setRows(updatedRows);

      // return newRow;
    } catch (error) {
      console.log(error, 'erro update fob')
      const msgError = error?.response?.data?.message || error.message
      setFobs(clone)
      alert(msgError)
    }

  }

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        loading={loader}
        rows={fobs}
        columns={colunms}
        pageSize={100}
        rowsPerPageOptions={[100]}
        onCellEditCommit={editCommit}
      //processRowUpdate={processRowUpdate}
      //experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
}
