import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { parseNumberFormat } from '../Utils/Format';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  cellCanal: {
    justifyContent: 'center',
    display: 'flex'
  }
}));

function getCellCanal(canal) {
  const filter = 'drop-shadow(1px 2px 2px #000000)'
  let backgroundColor
  switch (canal?.toLowerCase()) {
    case 'vermelho':
      backgroundColor = 'red'
      break
    case 'amarelo':
      backgroundColor = 'yellow'
      break
    case 'verde':
      backgroundColor = 'green'
      break
    case 'cinza':
      backgroundColor = 'gray'
      break
    case 'analise fiscal':
      backgroundColor = 'blue'
      break
    default:
      backgroundColor = ''
  }

  return (
    <div
      style={{
        borderRadius: '100%',
        height: '18px',
        width: '18px',
        ...(backgroundColor && {
          filter,
          backgroundColor,
        }),
        ...(!backgroundColor && {
          filter,
          border: 'purple solid 2px'
        }),
      }}
    >
    </div>

  )

}

export default function TabelaEmbarcado(props) {
  const classes = useStyles();
  const rows = props.pedidos;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell align='center' width='80px'>
              Processo
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Status Embarque
            </StyledTableCell>
            {props.enableManufacturer && (<StyledTableCell align='center' width='80px'>
              Canal
            </StyledTableCell>)}
            <StyledTableCell align='center' width='80px'>
              Pedido
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Produto
            </StyledTableCell>
            {props.enableManufacturer && (<StyledTableCell align='center' width='80px'>
              Fabricante
            </StyledTableCell>)}
            <StyledTableCell align='center' width='80px'>
              Data da Compra
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Data Previsão Chegada
            </StyledTableCell>
            <StyledTableCell align='center' width='80px'>
              Quantidade Embarcada
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.processoNumero}>
              <StyledTableCell align='center'>
                {row.processoNumero}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {row.statusProcesso}
              </StyledTableCell>
              {props.enableManufacturer && (<StyledTableCell className={classes.cellCanal} align='center' >
                {getCellCanal(row.canal)}
              </StyledTableCell>)}
              <StyledTableCell align='center'>
                {row.pedidoNumero}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {row.produtoChave}
              </StyledTableCell>
              {props.enableManufacturer && (<StyledTableCell align='center'>
                {row.fabricante || ''}
              </StyledTableCell>)}
              <StyledTableCell align='center'>
                {row.DataPedidoCompra}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {row.dataPrevisaoChegada}
              </StyledTableCell>
              <StyledTableCell align='center'>
                {parseNumberFormat(row.quantidadeEmbarcada)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}
